<template>
  <mi-dialog
    :model-value="modelValue"
    class="job-save-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <h4 v-if="!isTableSaved" class="q-mt-none q-mb-md"> Save for later </h4>

    <mi-form @submit="onSave">
      <div class="job-edit-dialog__input">
        <mi-text-field
          v-model="jobInputName"
          :rules="[maxLength(80)]"
          label="Add a name (optional)"
          placeholder="Give this job a name"
          outlined
        ></mi-text-field>
      </div>
      <div class="flex justify-end">
        <mi-btn
          :loading="onSaveInProgress"
          disable="false"
          type="submit"
        >
          Save
        </mi-btn>
      </div>
    </mi-form>
  </mi-dialog>
</template>

<script>
  import { ref, watch } from 'vue'
  import { maxLength } from '@/utils/validators'

  export default {
    name: 'SavedTablesDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      item: {
        type: Object,
        required: false,
        default: () => {}
      },
      onSaveCallback: {
        type: Function,
        required: false,
        default: () => {}
      },
      onSaveInProgress: {
        type: Boolean,
        required: false,
        default: false
      },
      isTableSaved: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    emits: ['update:model-value'],
    setup(props, { emit }) {
      const jobInputName = ref(props.item.name || '')

      const emitUpdateModelValue = (value = false) => {
        emit('update:model-value', value)
      }

      // This dialog serves two purposes: the edit name operation and the saving of newly calculated tables.
      const onSave = () => {
        const { correlationId } = props.item
        props.onSaveCallback({ correlationId, name: jobInputName.value })
      }

      watch(() => props.item, () => {
        jobInputName.value = props.item.name || ''
      })

      return {
        jobInputName,
        maxLength,
        onSave,
        emitUpdateModelValue
      }
    }
  }
</script>

<style lang="scss">
  .job-save-dialog {
    ::v-deep(.q-field--labeled) {
      font-size: .625rem;
      padding-top: 2px;
    }

    .q-dialog__inner > .mi-card {
      width: 683px;
    }

    &__body-paragraph {
      line-height: 24px;
    }

    &__input {
      padding-top: 24px;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .job-save-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
