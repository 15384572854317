<template>
  <div class="back-to-search__label" @click="handleBackToSearch">
    <img
      src="@/assets/images/arrow-left.svg"
      :alt="altText"
    />
    <span class="back-to-search__text">
      {{ backToSearchText }}
    </span>
  </div>
</template>

<script>
  export default {
    name: 'SavedTablesBackToSearch',
    props: {
      backToSearchText: {
        type: String,
        required: false,
        default: 'Back to search'
      },
      altText: {
        type: String,
        required: false,
        default: 'Return to variant management search'
      }
    },
    emits: ['on-back-to-search-click'],
    setup(props, { emit }) {
      const handleBackToSearch = () => {
        emit('on-back-to-search-click')
      }
      return { handleBackToSearch }
    }
  }
</script>

<style lang="scss" scoped>
  .back-to-search {
    &__label {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
    }

    &__text {
      color: $mi-anthracite-800;
      text-transform: uppercase;
      font-size: $mi-field-font-size;
      cursor: pointer;
    }
  }
</style>
