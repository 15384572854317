import formatDate, { formatLocaleDate } from '@/utils/formatDate'
import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'

export const removePrefixFromPP = pp => removePrefixFromPlanningPeriod(pp)
export const sortDate = (a, b) => new Date(a.createdDate) - new Date(b.createdDate)

export const savedTablesListColumns = [
  {
    name: 'tableName',
    label: 'Table name',
    align: 'left',
    field: 'name',
    classes: 'capitalize',
    sortable: true,
    style: 'width: 30%; text-transform: capitalize'
  },
  {
    name: 'pmName',
    label: 'Product model',
    align: 'left',
    field: 'pmName',
    classes: 'capitalize',
    sortable: true,
    style: 'width: 20%; text-transform: capitalize'
  },
  {
    name: 'planningPeriod',
    align: 'left',
    label: 'Planning period',
    field: ({ planningPeriod } = {}) => removePrefixFromPP(planningPeriod),
    sortable: true,
    style: 'width: 20%; text-transform: capitalize'
  },
  {
    name: 'createdDate',
    label: 'Date (start - end)',
    align: 'left',
    sortable: true,
    field: ({ createdDate, calculationCompleteDate } = {}) => ({ createdDate, calculationCompleteDate }),
    sort: (a, b) => sortDate(a, b),
    format: ({ createdDate, calculationCompleteDate } = {}) => `
    ${ formatDate(createdDate) } - ${ calculationCompleteDate ? formatDate(calculationCompleteDate) : '' }`,
    style: 'width: 30%'
  },
  {
    name: 'job-status',
    label: 'Status',
    align: 'left',
    field: 'jobStatus',
    sortable: true,
    style: 'width: 10%'
  },
  {
    name: 'actions',
    label: '',
    align: 'left',
    field: 'actions'
  }
]

export const SAVED_TABLES_PROVIDER = 'savedTablesProvider'

export const buildExcelName = (tableName, pp, pmName, split) => {
  let excelName = tableName ? `${ tableName.replace(/\s+/g, '_') }_VMT` : 'VMT'

  excelName += pp ? `_${ pp }` : ''
  excelName += pmName ? `_${ pmName.replace(/\s+/g, '_') }` : ''

  const currentDate = new Date()
  const formattedDate = formatLocaleDate(currentDate)

  excelName += formattedDate ? `_${ formattedDate }` : ''
  excelName += split ? '_split' : ''

  return `${ excelName }.xlsx`
}
