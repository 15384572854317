<template>
  <mi-td>
    <div class="flex">
      <mi-badge class="text-uppercase q-mr-xs" :class="getStatusColor()">
        {{ availableStatus[status]?.title ?? 'unknown' }}
      </mi-badge>
    </div>
  </mi-td>
</template>

<script>
  import { ref } from 'vue'
  import { RESULT_STATUS } from '@/constants'

  export default {
    name: 'SavedTablesListActionsColumn',
    props: {
      status: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup(props) {
      const availableStatus = ref(RESULT_STATUS)

      const getStatusColor = () => {
        const statusClass = 'status-column__badge'
        if (availableStatus.value[props.status]) {
          if (props.status === 'IN_PROGRESS') return `${ statusClass }--in-progress`
          if (props.status === 'FAILED') return `${ statusClass }--failed`
          return `${ statusClass }--success`
        }
        return 0
      }

      return { availableStatus, getStatusColor }
    }
  }
</script>

<style lang="scss">
  .status-column {
    &__badge {
      &--in-progress {
        background-color: $mi-blue-800;
      }

      &--success {
        background-color: $mi-green-700;
      }

      &--failed {
        background-color: $mi-red-800;
      }
    }
  }
</style>
