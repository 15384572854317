<template>
  <div id="saved-tables">
    <saved-tables-container></saved-tables-container>
  </div>
</template>

<script>
  import SavedTablesContainer from '@/components/saved-tables/SavedTablesContainer.vue'

  export default {
    name: 'SavedTables',
    components: { SavedTablesContainer }
  }
</script>
