<template>
  <mi-refresh-btn
    class="saved-tables-filter__refresh-btn"
    @clicked-refresh-btn="onHandleFilterChange()"
  ></mi-refresh-btn>
  <div class="saved-tables-filter__divider"></div>
  <mi-btn
    :class="filters.todaysFilter && 'saved-tables-filter__filter-btn--selected'"
    flat
    :color="btnColor"
    @click="onHandleFilterChange({ filterKey: 'todaysFilter', isBooleanType: true })"
  >
    From today
  </mi-btn>
  <div class="saved-tables-filter__divider"></div>
  <div class="q-ml-lg col-lg-4 col-sm-6">
    <mi-text-field
      :model-value="filters.searchField"
      bg-color="white"
      debounce="300"
      placeholder="Search for a table name or product model"
      clearable
      hide-bottom-space
      outlined
      @update:model-value="(value) => onHandleFilterChange({ filterKey: 'searchField', value })"
    >
      <template #prepend>
        <mi-icon name="search"></mi-icon>
      </template>
    </mi-text-field>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'

  export default {
    name: 'SavedTablesFiltersBar',
    components: { MiBtn },
    props: {
      filters: {
        type: Object,
        required: false,
        default: () => {}
      }
    },
    emits: ['on-handle-filters'],
    setup(props, { emit }) {
      const btnColor = computed(() => (props.filters.todaysFilter ? 'white' : 'primary'))

      const onHandleFilterChange = ({ filterKey = '', value = '', isBooleanType = false } = {}) => {
        const filterValue = isBooleanType ? !props.filters[filterKey] : value || ''
        emit('on-handle-filters', filterKey, filterValue)
      }

      return { btnColor, onHandleFilterChange }
    }
  }
  </script>

  <style lang="scss">
  .saved-tables-filter {
    &__refresh-btn {
      width: 48px;
      height: 48px;
      padding-right: 1rem;
    }

    &__divider {
      border: 1px solid $mi-anthracite-50;
      height: 27px;
    }

    &__filter-btn {
      &--selected {
        background-color: $mi-anthracite-800;
      }
    }
  }
</style>
