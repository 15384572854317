<template>
  <mi-td>
    <div class="actions-column">
      <mi-btn color="transparent" @click.stop>
        <q-icon class="text-dark" size="small">
          <img src="@/assets/images/dots_vertical.svg" alt="Saved tables actions" />
        </q-icon>
        <mi-menu>
          <q-list class="actions-column__list">
            <q-item v-close-popup clickable @click="onDropdownDeleteClick">
              <q-item-section>
                <q-item-label>Delete</q-item-label>
              </q-item-section>
            </q-item>
            <q-item v-close-popup clickable @click="onEditClick">
              <q-item-section>
                <q-item-label>Edit table name</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              v-close-popup
              :disable="!isSuccessJob"
              clickable
              class="export-data-btn"
              @click="onExportClick(false)"
            >
              <q-item-section>
                <q-item-label>Export data</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
              v-close-popup
              :disable="!isSuccessJob"
              clickable
              class="export-data-splitted-btn"
              @click="onExportClick(true)"
            >
              <q-item-section>
                <q-item-label>Export data (Split ID cells)</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </mi-menu>
      </mi-btn>
      <mi-btn v-if="isSuccessJob" color="transparent">
        <q-icon class="text-dark" size="small">
          <img src="@/assets/images/redirect_arrow.svg" alt="Navigate to Search" />
        </q-icon>
      </mi-btn>
      <saved-tables-delete-dialog
        v-model="isDeleteJobDialogShown"
        :item="row"
        :on-delete-callback="onDeleteCallback"
        :on-delete-in-progress="deleteCombinationInProgress"
      ></saved-tables-delete-dialog>
      <saved-tables-dialog
        v-model="isEditjobShown"
        :item="row"
        :on-save-callback="onEditCallback"
        :on-save-in-progress="editCombinationNameInProgress"
      ></saved-tables-dialog>
    </div>
  </mi-td>
</template>

<script>
  import { ref, inject, computed } from 'vue'
  import SavedTablesDeleteDialog from '@/components/saved-tables/SavedTablesDeleteDialog.vue'
  import SavedTablesDialog from '@/components/saved-tables/SavedTablesDialog.vue'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { SAVED_TABLES_PROVIDER } from '@/components/saved-tables/utils'
  import { SAVED_TABLES_DELETE_COMBINATION, SAVED_TABLES_EDIT_NAME_COMBINATION } from '@/utils/analytics/constants'

  export default {
    name: 'SavedTablesListActionsColumn',
    components: { SavedTablesDialog, SavedTablesDeleteDialog },
    props: {
      row: {
        type: Object,
        required: true
      }
    },
    emits: ['on-export-click'],
    setup(props, { emit }) {
      const {
        deleteCombination,
        editCombinationName,
        deleteCombinationInProgress,
        editCombinationNameInProgress
      } = inject(SAVED_TABLES_PROVIDER)
      const isDeleteJobDialogShown = ref(false)
      const isEditjobShown = ref(false)

      const isSuccessJob = computed(() => props.row.jobStatus === 'SUCCESS')

      const onExportClick = split => {
        isSuccessJob.value && emit('on-export-click', { row: props.row, split })
      }

      const onDropdownDeleteClick = () => {
        isDeleteJobDialogShown.value = true
      }

      const onEditClick = () => {
        isEditjobShown.value = true
      }

      const onDeleteCallback = async ({ correlationId }) => {
        try {
          await deleteCombination(correlationId)
          recordAnalytics(SAVED_TABLES_DELETE_COMBINATION)
        }
        finally {
          isDeleteJobDialogShown.value = false
        }
      }

      const onEditCallback = async ({ correlationId, name }) => {
        try {
          await editCombinationName(correlationId, name)
          recordAnalytics(SAVED_TABLES_EDIT_NAME_COMBINATION)
        }
        finally {
          isEditjobShown.value = false
        }
      }

      return {
        onExportClick,
        isSuccessJob,
        deleteCombinationInProgress,
        editCombinationNameInProgress,
        isDeleteJobDialogShown,
        isEditjobShown,
        onEditClick,
        onDeleteCallback,
        onEditCallback,
        onDropdownDeleteClick
      }
    }
  }
</script>

<style lang="scss">
  .actions-column {
    display: flex;
    justify-items: center;

    &__list {
      width: 220px;
    }
  }
</style>
