<template>
  <div class="saved-tables-list">
    <div class="saved-tables-list__wrapper">
      <div v-if="rows" class="saved-tables-list__wrapper--content">
        <mi-table
          ref="miTable"
          :columns="columns"
          :rows="rows"
          :loading="loading"
          class="saved-tables full-height"
          column-sort-order="da"
          loading-label="Loading results. Please wait ..."
          no-data-label="Your table results will be displayed here when requested from you."
          row-key="id"
          hide-pagination
          sticky-header
          striped
          wrap-cells
          @row-click="(_evt, row) => routeToPossibleCombinations(row)"
        >
          <template #body-cell-actions="{ row }">
            <saved-tables-list-actions-column :row="row" @on-export-click="onExportClick">
            </saved-tables-list-actions-column>
          </template>
          <template #body-cell-job-status="{ value }">
            <saved-tables-list-status-column :status="value">
            </saved-tables-list-status-column>
          </template>
        </mi-table>
      </div>
    </div>
  </div>
</template>

<script>
  import SavedTablesListActionsColumn from '@/components/saved-tables/SavedTablesListActionsColumn.vue'
  import SavedTablesListStatusColumn from '@/components/saved-tables/SavedTablesListStatusColumn.vue'
  import { COMBINATIONS_ROUTE, SAVED_TABLES_ROUTE } from '@/router/routeNames'
  import notify from '@/utils/notify'
  import { useRouter } from 'vue-router'

  export default {
    name: 'SavedTablesList',
    components: { SavedTablesListActionsColumn, SavedTablesListStatusColumn },
    props: {
      rows: {
        type: Array,
        required: true
      },
      columns: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      }
    },
    emits: ['download-calculation-results'],
    setup(props, { emit }) {
      const router = useRouter()

      const onExportClick = ({ row, split }) => {
        emit('download-calculation-results', { row, split })
      }

      const routeToPossibleCombinations = row => {
        const query = {
          correlationId: row.correlationId,
          pp: btoa(row.planningPeriod),
          productModelBusinessName: btoa(row.pmName)
        }
        if (row.jobStatus === 'SUCCESS') {
          router.push({
            name: COMBINATIONS_ROUTE.name,
            query,
            params: { previousPage: SAVED_TABLES_ROUTE.name }
          })
        }
        else {
          notify({
            title: 'Not Ready Yet',
            content: `The calculation for ${ row.pmName } is not ready yet. Please try again later.`,
            type: 'warning',
            progress: false
          })
        }
      }

      return { onExportClick, routeToPossibleCombinations }
    }
  }
</script>

<style lang="scss">
  .saved-tables-list {
    &__wrapper {
      .q-inner-loading {
        height: 60vh;
      }

      &--content {
        width: 100%;

        .items-center {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
        }

        &__actions-list {
          width: 160px;
        }
      }
    }
  }
</style>
