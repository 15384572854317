<template>
  <mi-dialog
    :model-value="modelValue"
    class="job-delete-dialog"
    @update:model-value="emitUpdateModelValue"
  >
    <h3 class="q-mt-none q-mb-lg"> Remove from Saved tables? </h3>
    <p class="q-mb-xl job-delete-dialog__body-paragraph">
      Remove this combination result from your list. You will not be able to access it again in the future.
    </p>
    <template #actions>
      <mi-btn v-close-popup flat> Keep result </mi-btn>
      <mi-btn :loading="onDeleteInProgress" @click="onDeleteCallback(item)"> Remove </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  export default {
    name: 'SavedTablesDeleteDialog',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      item: {
        type: Object,
        required: false,
        default: () => {}
      },
      onDeleteCallback: {
        type: Function,
        required: false,
        default: () => {}
      },
      onDeleteInProgress: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['update:model-value'],
    setup(_props, { emit }) {
      const emitUpdateModelValue = (value = false) => {
        emit('update:model-value', value)
      }
      return {
        emitUpdateModelValue
      }
    }
  }
</script>

<style lang="scss">
  .job-delete-dialog {
    .q-dialog__inner > .mi-card {
      width: 683px;
    }

    &__body-paragraph {
      line-height: 24px;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .job-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
